<template>
  <div class="relative">
    <div class="pointer-events-none absolute w-[1400px] bottom-48 left-20 block">
      <img src="~/assets/images/process_out.svg" alt="Our process"
           class="hidden lg:block w-1/4 pointer-events-none opacity-[0.03]">
      <img src="~/assets/images/process-small_out.svg" alt="Our process"
           class="w-3/4 lg:hidden block ml-4 -mb-8 pointer-events-none opacity-[0.05]">
    </div>
    <div id="vertical-slider"  class="flex flex-col lg:flex-row items-center relative z-50">
      <div class="hidden lg:block overflow-hidden absolute w-full h-full pointer-events-none top-0">
        <div class="blue-radial absolute"></div>
      </div>
      <div class="w-full lg:w-1/3 ml-auto relative mt-8 lg:mt-0">
        <div class="max-w-sm mx-auto lg:ml-auto lg:mr-0 sticky">
          <h2 class="text-3xl lg:text-4xl gradient-clip mb-8" v-html="$t('pages.home.help')"></h2>
            <p class="max-w-[300px] mb-8" v-html="$t('pages.services.smalltexts.focus_results_description_2')"></p>
          <div class="block mb-2">
            <glow-button :to="localePath('/book')">
              {{$t('book')}}
            </glow-button>
          </div>

        </div>
      </div>
      <div class="w-full lg:w-2/3 overflow-x-hidden z-50 rounded-xl">
        <!-- Horizontal slider -->
        <div class="slides">
          <div class="rounded-xl overflow-hidden mx-6 w-[700px] slide" v-for="(slide, index) in slides">
            <div class="relative flex items-center">
              <div class="w-1/2 py-12 pl-12 pr-6">
                <img :src="slide.icon" :alt="`${slide.title} icon`" class="w-8 h-8 mb-4">
                <h3 class="text-2xl mb-4">{{slide.title}}</h3>
                <p>{{slide.body}}</p>
              </div>
              <div class="w-1/2 pt-6">
                <NuxtImg
                    provider="cloudinary"
                    format="webp"
                    width="400"
                    height="539"
                    :src="slide.image"
                    loading="lazy"
                    class="w-full h-auto right-0 bottom-0 object-contain"
                    :alt="slide.title"/>
              </div>
            </div>
          </div>
        </div>
        <!-- End of Horizontal slider -->

        <!-- Mobile slider -->
        <div class="lg:hidden">
          <carousel
              :items-to-show="1"
              :items-to-scroll="1"
              snap-align="start"
              class="my-2"
              ref="mobileslider">
            <Slide id="mobile-slider" v-for="(slide, index) in slides" :key="index">
              <div class="rounded-xl overflow-hidden w-full slide">
                <div class="relative flex flex-col items-center h-full">
                  <div class="w-full h-full p-4 text-left relative z-50">
                    <img :src="slide.icon" :alt="`${slide.title} icon`" class="w-8 h-8 mb-4">
                    <h3 class="text-2xl mb-4">{{slide.title}}</h3>
                    <p>{{slide.body}}</p>
                  </div>
                  <NuxtImg
                      provider="cloudinary"
                      format="webp"
                      width="400"
                      height="539"
                      :src="slide.image"
                      loading="lazy"
                      class="w-auto h-4/5 opacity-30 absolute right-0 bottom-0 z-10 object-contain"
                      :alt="slide.title"/>
                </div>
              </div>
            </Slide>
          </carousel>
          <div class="controls my-4 flex items-center justify-between">
            <button @click="slide('prev')" class="button button-outline2"><span></span><img src="~/assets/images/left-arrow-blue.svg" alt="Previous Slide"></button>
            <nuxt-link :aria-label="$t('pages.services.smalltexts.browse_all_services')" class="button button-outline2"
                       :to="localePath('/services')">
              <span></span>{{ $t('pages.services.smalltexts.browse_all_services') }}
            </nuxt-link>
            <button @click="slide('next')" class="button button-outline2"><span></span><img src="~/assets/images/right-arrow-blue.svg" alt="Next Slide"></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import {Carousel, Slide} from 'vue3-carousel'
import glowButton from "../../blocks/glow-button/glow-button";
import icon1 from '~/assets/images/icons/strategy_and_design.svg'
import icon2 from '~/assets/images/icons/development.svg'
import icon3 from '~/assets/images/icons/automation.svg'
import icon4 from '~/assets/images/icons/compliance.svg'
import icon5 from '~/assets/images/icons/maintenance.svg'

const {t} = useI18n()

const slides = ref([
  {
    icon: icon1,
    title: t('pages.home.design'),
    body: t('pages.home.design_text'),
    image: '/images/slider3-design_and_creative.png',
  },
  {
    icon: icon2,
    title: t('pages.home.development'),
    body: t('pages.home.development_text'),
    image: '/images/slider3-devlopment.png',
  },
  {
    icon: icon3,
    title: t('pages.home.automation'),
    body: t('pages.home.automation_text'),
    image: '/images/slider3-automation.png',
  },
  {
    icon: icon4,
    title: t('pages.home.security'),
    body: t('pages.home.security_text'),
    image: '/images/slider3-compliance.png',
  },
  {
    icon: icon5,
    title: t('pages.home.maintenance'),
    body: t('pages.home.maintenance_text'),
    image: '/images/slider3-maintenance.png',
  },
])

const mobileslider = ref(null)

const slide = (direction) => {
  if (direction === 'next') {
    mobileslider.value.next()
  } else {
    mobileslider.value.prev()
  }
}

onMounted(() => {
  if (process.client && window.innerWidth >= 1024) {
    const {$gsap,$ScrollTrigger} = useNuxtApp()
    const animation = $gsap.to(".slides", {xPercent: -80, duration: 20, ease: "none"})
    const st = $ScrollTrigger.create({
      trigger: "#vertical-slider",
      start: "0% 0%",
      end: "+=2000px",
      pin: true,
      animation: animation,
      scrub: 1,
    });
  }
})

</script>
